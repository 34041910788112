import type { Request, Headers, BodyInit } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare module 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts' {
    export interface IRequestOptions {}

    export class ApiRequestOptions<T extends IRequestOptions> {
        readonly request: Request;
        readonly parsedOptions: T;

        get headers(): Headers;
        get body(): BodyInit;

        constructor(request: Request, options: T);

        static parseFromRequest<T extends IRequestOptions>(request: Request, options: T): ApiRequestOptions<T>;
    }
}

export {}
